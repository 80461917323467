<template>
	<div class="container">
		<div class="home-header">
			<div class="head-left">
				<div style="display: inline-block; vertical-align:middle;width: 100%;;" @keyup.enter="search">
					<!-- <span class="send" @click="search">搜索</span> -->
					<input placeholder="请输入图书名字搜索" type="search" v-model="bookName" class="input-with-select search">
					<button class="search_btn"  @click="search">搜索</button>
					<!-- </el-input> -->
					<!-- <form @submit.prevent style="display: inline-block;width: 96%;">
						<input placeholder="从万千资料中搜索" @keyup.enter="search"  type="search" v-model="bookName" class="input-with-select search">
					</form> -->
				</div>
			</div>
			<div class="head-right">
				<!-- <router-link to="/message"><img src="../assets/images/message.png" /></router-link> -->
			</div>
		</div>
		<div class="blian22"></div>
		<div class="swiper">
			<section class="my-swiper">
				<swiper :options="swiperOption" v-if="swiperList.length>0" >
					<swiper-slide ref="swiperOption"  class="my-swp-silde"  v-for="(slide, key) in swiperList" :key="key" data-id="slide.id">
						<img class="my-swp-img" :src="slide.bannerPic" @click="goUrl(slide)" alt="">
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</section>
		</div>
		<div class="nav">
			<ul>
				<li>
					<router-link to="/teacher">
						<span>
							<!-- <img src="../assets/images/icon.png"> -->
							<img src="https://cdn.rongdeji.cn/icon186169c9.png">
						</span>
						<p>教师备课课件</p>
					</router-link>
				</li>
				<li v-for="(item,index) in bookTypArr1" :key="index">
					<router-link v-if="item.typeLink == '' || item.typeLink == null " :to="{path:pathArr[index]+'?bookType=' + item.typeId+'&titleName='+item.typeName}">
						<span><img :src="item.pic"></span>
						<p>{{ item.typeName }}</p>
					</router-link>
					<a target="_blank" v-else :href="item.typeLink">
						<span><img :src="item.pic"></span>
						<p>{{ item.typeName }}</p>
					</a>
				</li>
			</ul>
		</div>
		<!-- 图书配套增值资源 -->
		<div class="home-cont">
			<h3>图书配套资源</h3>
			<ul>
				<!-- v-show="item.learnSection==0 || item.learnSection==learnSection" -->
				<!-- class="increment" -->
				<li class="increment" v-for="(item,index) in bookTypArr2" :key="index" @click="goBookList(item,item.typeId,item.typeName)">
					<!-- class="incrementImg" -->
					<img class="incrementImg" :src="item.pic">
					<!-- <h4>{{ item.typeName }}</h4> -->
				</li>
				<!-- class="verticalNew" -->
			</ul>
		</div>
		<!-- 听力测试音频   荣德基教育 字词趣学 -->
		<!-- v-show="item.learnSection==0 || item.learnSection==learnSection" -->
		<div class="home-cont" v-for="(item,index) in bookTypArr3" :key="index" @click="goBookList(item,item.typeId,item.typeName)">
			<h3 v-if="item.typeName == '荣德基微课堂'">{{ item.typeName }} <img style="width: 1rem;height: 1rem;" src="../assets/images/hot.png"></h3>
			<h3 v-else>{{ item.typeName }}</h3>
			<ul>
				<li class="showImg">
					<img class="hearImg" :src="item.pic">
				</li>
			</ul>
		</div>
		<QrCode @hidden="hiddenShow" v-show="showQrCode"></QrCode>
	</div>
</template>
<script>
	// @ is an alias to /src
	import { banners, bookTypes } from "@/assets/js/api";
	import { Indicator } from 'mint-ui'; //加载样式
	import QrCode from '@/components/QrCode.vue'
	import 'swiper/dist/css/swiper.css';
	import { swiper, swiperSlide } from 'vue-awesome-swiper';
	export default {
		name: 'home',
		components: {
			QrCode,
			swiper,
			swiperSlide
		},
		data() {
			return {
				pathArr:['/english','/chinese','/testpaper'],
				gradeName:"",
				learnSectionName:"",
				volumesName:"",
				learnSection: '',
				showQrCode: false,
				swiperList: [],
				bookTypArr1: [],
				bookTypArr2: [],
				bookTypArr3: [],
				swiperOption: {
					autoplay: {
						disableOnInteraction: true, // 用户操作swiper之后，是否禁止autoplay
						delay: 2000, // 自动切换的时间间隔（单位ms）
					},
					initialSlide: 0,
					loop: true,
					pagination: {
						el: '.swiper-pagination'
					}, // 分页按钮
					paginationClickable: true,
					onSlideChangeEnd: swiper => {
						//console.log('onSlideChangeEnd', swiper.realIndex)
					},
				},
				bookName:"" // 搜索框内容
			}
		},
		methods: {
			//跳转轮播投
			goUrl(info){
				window.location.href = info.linkUrl
			},
			hiddenShow: function() {
				this.showQrCode = false
			},
			getBanners() {
				banners().then(res => {
					if(res.code == 0) {
						this.swiperList = res.banners;
					}
				});
			},
			getbookTypes() {
				//  	Indicator.open()
				bookTypes().then(res => {
					if(res.code == 0) {
						Indicator.close()
						// console.log(res)
						this.bookTypArr1 = res.types.filter(function(item) {
							return item.num == 1
						})
						this.bookTypArr2 = res.types.filter(function(item) {
							return item.num == 2
						})
						this.bookTypArr3 = res.types.filter(function(item) {
							return item.num == 3
						})
					}
				});
			},
			//去图书列表
			goBookList(info, id, title) {
				if(info.typeLink){
					window.location.href = info.typeLink
				}else{
					this.$router.push({ //核心语句
						path: '/demand', //跳转的路径
						query: {
							bookType: id,
							titleName: title
						}
					})
				}
			},
			// 搜索
			search(){
				this.$router.push({ //核心语句
					path: '/search', //跳转的路径
					query: {
						titleName: this.bookName
					}
				})
			}
		},
		activated() {
			// this.learnSection = localStorage.getItem('learnSection');
			// let obj = JSON.parse(localStorage.getItem('selName'));
			// this.gradeName = obj.gradeName;
			// this.learnSectionName = obj.learnSectionName;
			// this.volumesName = obj.volumesName;
		},
		mounted() {
			// if(localStorage.getItem('learnSection')) {
			// 	this.learnSection = localStorage.getItem('learnSection');
			// 	let obj = JSON.parse(localStorage.getItem('selName'));
			// 	this.gradeName = obj.gradeName;
			// 	this.learnSectionName = obj.learnSectionName;
			// 	this.volumesName = obj.volumesName;
			// }else {
			// 	this.$router.push({ //核心语句
			// 		path: '/grade', //跳转的路径
			// 	})
			// }
			this.getBanners();
			this.getbookTypes();
			/* 判断是否为微信环境 */
			var ua = window.navigator.userAgent.toLowerCase();
			let that = this;
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){      //判断是否是微信环境
				//微信环境下
			    console.log('微信环境')
			}else{
			    //非微信环境逻辑
				console.log('非微信环境')
			}
			/* end */
		},
	}
</script>
<style>
	.search{
		width: 80%;
		border:none;
		border-radius: 25px;
		font-size: 0.7rem;
		padding: 0 1.2rem;
		background-image:url(../assets/images/search.png);
		background-repeat: no-repeat;
		background-size: 18px 18px;
		background-position-x: 0.2rem;
		background-position-y: center;
		background-color: #F4F4F4;
	}
	.search_btn{
		background-color: #3ca6fe;
		color: #fff;
		font-size: 0.8rem;
		width: 18%; 
		border-radius: 5%; 
		margin-left: 6px;
	}
	.increment{
		float: left;
		width: 45%;
		text-align: center;
		margin-right: 0.8rem;
		box-shadow: #F8F8F8 0px 0px 4px;
		height: 9rem;
		border-radius: 3px;
	}
	.verticalNew{
		left: 5%;
		top: 70%;
	}
	
</style>