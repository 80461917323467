<template>
	<div>
		<div class="qrcode-cont vertical">
			<div class="codeclose"><img src="../assets/images/close.png" alt=""  @click="Hidden()"></div>
			<h3>获取本书更多资源</h3>
			<div class="codeImg">
				<img :src="codeurl" alt="">
			</div>
			<p>长按识别前往公众号</p>
		</div>
		<div class="popmaskcode"></div>
	</div>
</template>

<script>
	export default {
		name: 'qrcode',
		data() {
			return {};
		},
		props: {
			codeurl: {
				type: String,
				default: ''
			}
		},
		methods: {
			Hidden() {
				//通过$emit引用组件传过来的hidden()事件
				this.$emit("hidden");
			},
		},
		mounted() {
			console.log(this.codeurl)
		}
	}
</script>